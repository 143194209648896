import {
    Komodita,
    Maybe,
    OdberneMistoElektrinaPristroj,
    OdberneMistoPlynPristroj,
    SmlouvaOdbernehoMistaEdge,
    SmlouvaOdbernehoMistaKategorieMereni,
    SmlouvaOdbernehoMistaTypMereni,
    TypDiagramuHistorieSpotrebElektrina,
    TypDiagramuHistorieSpotrebPlyn,
    TypSendReportSmlouvaOdbernehoMista,
} from '@eon.cz/apollo13-graphql';
import {addDays, endOfDay, isAfter, isBefore, subMonths, subYears} from 'date-fns';
import {FormattedMessage, IntlShape} from 'react-intl';
import {Lang} from '../../../Lang';
import yup, {eanValidation, eicValidation, emailValidation, validOnlyDatum} from '../../Common/services/YupLocale';
import {OMHistorieSpotrebFilterModel} from '../model';
import {OdbernaMistaSmartMereniFilterModel} from '../model/OdbernaMistaSmartMereniFilterModel';
import {validationKategoriemereni} from '../service';
import {OMHistorieSpotrebService} from './OMHistorieSpotrebService';
import {OdbernaMistaService} from './OdbernaMistaService';

const string = yup.string();
export const validationSchemaOdbernaMistaFiltration = (komodita: Komodita | null | undefined) =>
    yup.object().shape({
        datumOd: validOnlyDatum({onlyValidate: true, validateOposite: true, opositeName: 'datumDo'}).nullable(),
        datumDo: validOnlyDatum({onlyValidate: true}).nullable(),
        eicEanSpotrebni: komodita === Komodita.ELEKTRINA ? eanValidation : eicValidation,
    });
export const validationSchemaOdbernaMistaUpdate = yup.object().shape({
    nazev: string.max(30),
});
export const validationSchemaOdbernaMistaHistorieSpotrebElektrinaFiltration = (
    kategorieMereni: Maybe<Maybe<SmlouvaOdbernehoMistaKategorieMereni>[]> | undefined,
    typMereni?: SmlouvaOdbernehoMistaTypMereni,
) =>
    yup.object().shape({
        idPristroje: string.required(),
        typDiagramu: string.required(),
        datumOd: validOnlyDatum({onlyValidate: true, validateOposite: true, opositeName: 'datumDo'})
            .required()
            .test(
                '',
                () => <FormattedMessage id={Lang.ODBERNA_MISTA_MAX_YEARS} />,
                (value) => {
                    if (value) {
                        const validateMaxThreeYears = isAfter(new Date(), endOfDay(subYears(new Date(value), -3)));
                        return !validateMaxThreeYears;
                    }
                    return true;
                },
            )
            .test(
                '',
                () => <FormattedMessage id="odberna.mista.kategorie.mereni.error" />,
                (value, ctx) => {
                    if (value && typMereni === SmlouvaOdbernehoMistaTypMereni.C) {
                        const isWithin = validationKategoriemereni(kategorieMereni, value, ctx.parent.datumDo);
                        return isWithin && isWithin?.length > 0;
                    }
                    return true;
                },
            ),
        datumDo: validOnlyDatum({onlyValidate: true})
            .required()
            .test(
                '',
                () => <FormattedMessage id="odberna.mista.warning.future" />,
                (value) => {
                    if (value) {
                        const validateFuture = isAfter(new Date(value), addDays(new Date(), -1));
                        return !validateFuture;
                    }
                    return true;
                },
            )
            .test(
                '',
                () => <FormattedMessage id="odberna.mista.kategorie.mereni.error" />,
                (value, ctx) => {
                    if (value && typMereni === SmlouvaOdbernehoMistaTypMereni.C) {
                        const isWithin = validationKategoriemereni(kategorieMereni, ctx.parent.datumOd, value);
                        return isWithin && isWithin?.length > 0;
                    }
                    return true;
                },
            ),
    });
export const validationSchemaOdbernaSmartFiltration = yup.object().shape({
    idPristroje: string.required(),
    typDiagramu: string.required(),
    datumOd: validOnlyDatum({onlyValidate: true, validateOposite: true, opositeName: 'datumDo'})
        .required()
        .test(
            '',
            () => <FormattedMessage id={Lang.ODBERNA_MISTA_MAX_YEARS} />,
            (value) => {
                if (value) {
                    const validateMaxThreeYears = isAfter(new Date(), endOfDay(subYears(new Date(value), -3)));
                    return !validateMaxThreeYears;
                }
                return true;
            },
        ),
    datumDo: validOnlyDatum({onlyValidate: true})
        .required()
        .test(
            '',
            () => <FormattedMessage id="odberna.mista.warning.future" />,
            (value) => {
                if (value) {
                    const validateFuture = isAfter(new Date(value), addDays(new Date(), -1));
                    return !validateFuture;
                }
                return true;
            },
        ),
});
export const validationSchemaOdbernaMistaReport = (komodita: Komodita | undefined | null) =>
    yup.object().shape({
        typReportu: string.required(),
        datumOd: string.nullable().when('typReportu', {
            is: (typReportu: TypSendReportSmlouvaOdbernehoMista) => typReportu === TypSendReportSmlouvaOdbernehoMista.DATA_Z_MERENI,
            then: () => validOnlyDatum({onlyValidate: true, validateOposite: true, opositeName: 'datumDo'}).required(),
        }),
        datumDo: string.nullable().when('typReportu', {
            is: (typReportu: TypSendReportSmlouvaOdbernehoMista) => typReportu === TypSendReportSmlouvaOdbernehoMista.DATA_Z_MERENI,
            then: () =>
                validOnlyDatum({onlyValidate: true})
                    .test(
                        '',
                        () => <FormattedMessage id="odberna.mista.vygenerovat.report.datrum.do.max.one.year" />,
                        (value, ctx) => {
                            if (value) {
                                const datumIsOneYear =
                                    isAfter(new Date(value), subMonths(new Date(ctx.parent.datumOd), -12)) &&
                                    ctx.parent.typReportu === TypSendReportSmlouvaOdbernehoMista.DATA_Z_MERENI;
                                return !datumIsOneYear;
                            }
                            return true;
                        },
                    )
                    .required(),
        }),
        email: emailValidation.required(),
        odbernaMistaElektrina: yup.object().when('omElektrinaVse', {
            is: (value: boolean) => komodita === Komodita.ELEKTRINA && !value,
            then: () =>
                yup.object().shape({
                    odbernaMista: yup.array().min(1, 'Vyberte alespoň jedno odberné místo.').required(),
                }),
        }),
        odbernaMistaPlyn: yup.object().when('omPlynVse', {
            is: (value: boolean) => komodita === Komodita.PLYN && !value,
            then: () =>
                yup.object().shape({
                    odbernaMista: yup.array().min(1, 'Vyberte alespoň jedno odberné místo.').required(),
                }),
        }),
    });

/**
 * Zvaliduje hodnoty nastavení filtru.
 *
 * @param {OdbernaMistaSmartMereniFilterModel} values - aktuální hodnoty nastavení filtru
 */
export const validateSmartMereni = (
    values: OdbernaMistaSmartMereniFilterModel,
    pristroje: Maybe<OdberneMistoElektrinaPristroj>[] | undefined,
    smlouvyOdbernychMist: SmlouvaOdbernehoMistaEdge[] | undefined,
    intl: IntlShape,
) => {
    const {datumOd, datumDo, typDiagramu, idPristroje} = values;

    const datum = !!datumOd && !!datumDo;

    const validateSmlouva = smlouvyOdbernychMist?.reduce((_, {node}) => {
        return isBefore(new Date(datumOd as string), new Date(node.datumDo));
    }, false)
        ? {
              field: 'datumOd',
              message: intl.formatMessage({id: Lang.ODBERNA_MISTA_WARNING_SMLOUVA_JINY}),
          }
        : undefined;

    const validateElektrinaDatumOdDatumDoIsMaxOneMonth = (datumOd: Date, datumDo: Date) => {
        const nowPlusOneMonth = new Date(datumOd);
        nowPlusOneMonth.setMonth(new Date(datumOd).getMonth() + 1);
        return nowPlusOneMonth <= new Date(datumDo)
            ? {
                  field: 'datumDo',
                  message: intl.formatMessage({id: Lang.ODBERNA_MISTA_WARNING}, {obdobi: typDiagramu === 'stavRegistru' ? 'rok' : 'měsíc'}),
              }
            : undefined;
    };

    const rawErrorDatum =
        datum && typDiagramu === 'stavRegistru'
            ? OMHistorieSpotrebService.validateHodinovy(new Date(datumOd), new Date(datumDo), intl)
            : datum && typDiagramu !== 'stavRegistru'
              ? validateElektrinaDatumOdDatumDoIsMaxOneMonth(new Date(datumOd), new Date(datumDo))
              : undefined;
    const validateFutureError = OMHistorieSpotrebService.validateFutureDatumDo(new Date(datumDo as string), intl);

    let pristrojDatumOd: Maybe<{field: string; message: string}> | undefined;
    let pristrojDatumDo: Maybe<{field: string; message: string}> | undefined;
    if (idPristroje) {
        const pristroj = pristroje?.find((pristroj) => OdbernaMistaService.getFullIdPristroje(pristroj?.idPristroje, pristroj?.id) === idPristroje);

        pristrojDatumOd =
            new Date(datumOd as string) < new Date(pristroj?.datumOd) || new Date(datumOd as string) > new Date(pristroj?.datumDo)
                ? {
                      field: 'datumOd',
                      message: intl.formatMessage({id: Lang.ODBERNA_MISTA_WARNING}, {obdobi: typDiagramu === 'stavRegistru' ? 'rok' : 'měsíc'}),
                  }
                : undefined;

        pristrojDatumDo =
            datumDo && datumDo > pristroj?.datumDo
                ? {
                      field: 'datumDo',
                      message: intl.formatMessage({id: Lang.ODBERNA_MISTA_WARNING}, {obdobi: typDiagramu === 'stavRegistru' ? 'rok' : 'měsíc'}),
                  }
                : undefined;
    }

    return {
        datumOd: rawErrorDatum ?? pristrojDatumOd ?? validateSmlouva ?? undefined,
        datumDo: pristrojDatumDo ?? validateFutureError ?? undefined,
    };
};

export const TypDiagramu = {
    CTVRTHODINOVY: 'Čtvrthodinový',
    HODINOVY: 'Hodinový',
} as const;

export type TypDiagramu = (typeof TypDiagramu)[keyof typeof TypDiagramu];

export const validateHistorieSpotreb = (
    values: Partial<OMHistorieSpotrebFilterModel>,
    komodita: Komodita,
    pristroje: Maybe<Maybe<OdberneMistoElektrinaPristroj | OdberneMistoPlynPristroj>[]> | undefined,
    typyDiagramu: Array<TypDiagramuHistorieSpotrebElektrina | TypDiagramuHistorieSpotrebPlyn>,
    smlouvyOdbernychMist: SmlouvaOdbernehoMistaEdge[] | undefined,
    intl: IntlShape,
) => {
    const {datumOd, datumDo, idPristroje, typDiagramu} = values;

    const datum = !!datumOd && !!datumDo;

    const isTypDiagramuHodinovy = typyDiagramu.reduce((sum, {id, nazev}) => (typDiagramu === id ? nazev.split(' ')[0] : sum), '') === TypDiagramu.HODINOVY;

    // const validateElektrinaDatumOdDatumDoIsMaxOneMonth = (datumOd: Date, datumDo: Date) => {
    //     const nowPlusOneMonth = new Date(datumOd);
    //     nowPlusOneMonth.setMonth(new Date(datumOd).getMonth() + 1);
    //     return nowPlusOneMonth <= new Date(datumDo)
    //         ? {
    //               field: 'datumDo',
    //               message: intl.formatMessage(
    //                   {id: Lang.ODBERNA_MISTA_WARNING},
    //                   {obdobi: isTypDiagramuHodinovy || komodita === Komodita.PLYN ? 'rok' : 'měsíc'},
    //               ),
    //           }
    //         : undefined;
    // };

    // const validateByKomodita = komodita === Komodita.PLYN ? OMHistorieSpotrebService.validateHodinovy : validateElektrinaDatumOdDatumDoIsMaxOneMonth;

    const validateSmlouva = smlouvyOdbernychMist?.reduce((_, {node}) => {
        return isBefore(new Date(datumOd as string), new Date(node.datumDo));
    }, false)
        ? {
              field: 'datumOd',
              message: intl.formatMessage({id: Lang.ODBERNA_MISTA_WARNING_SMLOUVA_JINY}),
          }
        : undefined;

    const rawErrorDatum =
        datum && isTypDiagramuHodinovy
            ? OMHistorieSpotrebService.validateHodinovy(new Date(datumOd), new Date(datumDo), intl)
            : datum && !isTypDiagramuHodinovy
              ? OMHistorieSpotrebService.validateHodinovy(new Date(datumOd), new Date(datumDo), intl)
              : undefined;
    const pristroj = pristroje?.find((p) => OdbernaMistaService.getFullIdPristroje(p?.idPristroje, p?.id) === idPristroje);

    const validateFutureError = OMHistorieSpotrebService.validateFutureDatumDo(new Date(datumDo as string), intl);

    let pristrojDatumOd: Maybe<{field: string; message: string}> | undefined;
    let pristrojDatumDo: Maybe<{field: string; message: string}> | undefined;

    if (pristroj) {
        pristrojDatumOd =
            new Date(datumOd as string) < new Date(pristroj.datumOd) || new Date(datumOd as string) > new Date(pristroj.datumDo)
                ? {
                      field: 'datumOd',
                      message: intl.formatMessage(
                          {id: Lang.ODBERNA_MISTA_WARNING},
                          {obdobi: isTypDiagramuHodinovy || komodita === Komodita.PLYN ? 'rok' : 'měsíc'},
                      ),
                  }
                : undefined;

        pristrojDatumDo =
            datumDo && datumDo > pristroj.datumDo
                ? {
                      field: 'datumDo',
                      message: intl.formatMessage(
                          {id: Lang.ODBERNA_MISTA_WARNING},
                          {obdobi: isTypDiagramuHodinovy || komodita === Komodita.PLYN ? 'rok' : 'měsíc'},
                      ),
                  }
                : undefined;
    }

    return {
        datumOd: rawErrorDatum ?? pristrojDatumOd ?? validateSmlouva ?? undefined,
        datumDo: pristrojDatumDo ?? validateFutureError ?? undefined,
    };
};
