// locale.js
import {endOfDay, format, isAfter, isBefore, isFirstDayOfMonth, isFuture, isValid, startOfDay, subDays, subYears} from 'date-fns';
import {FormattedMessage} from 'react-intl';
import * as yup from 'yup';
import {validateDatumOdIsLowerOrEqualThenDatumDo} from '../../Utils/DateOperations';
import {validateDate} from './CommonService';
import {isNotNullOrUndefinedOrEmpty} from './FileService';

yup.setLocale({
    mixed: {
        required: () => <FormattedMessage id="yup.required" />,
    },
    string: {
        max: ({max}) => <FormattedMessage id="yup.max" values={{znak: max, pismeno: max === 1 ? '' : max > 1 && max < 5 ? 'y' : 'ů'}} />,
        min: ({min}) => <FormattedMessage id="yup.min" values={{znak: min, pismeno: min === 1 ? '' : min > 1 && min < 5 ? 'y' : 'ů'}} />,
        email: () => <FormattedMessage id="yup.email" />,
    },
    array: {
        min: () => <FormattedMessage id="yup.required" />,
    },
    number: {
        max: ({max}) => <FormattedMessage id="yup.max" values={{znak: max, pismeno: max === 1 ? '' : max > 1 && max < 5 ? 'y' : 'ů'}} />,
        min: ({min}) => <FormattedMessage id="yup.min" values={{znak: min, pismeno: min === 1 ? '' : min > 1 && min < 5 ? 'y' : 'ů'}} />,
    },
});

// yup.addMethod(yup.string, 'customMethod', (param) => {
//     return yup.string().test('customMethod', 'Custom method failed', (value, ctx) => {
//         // your custom validation logic here
//         console.log(`%c 🚨 -> param: `, 'color: #e13019', {value, param});
//         return true;
//     });
// });

const string = yup.string();

export const ZADVAL033 = string.nullable().matches(/^(0|[1-9]\d{0,14})$/, () => <FormattedMessage id="yup.number.zadval033" />);
export const ZADVAL034 = string.nullable().matches(/^(0|[1-9]\d{0,15})$/, () => <FormattedMessage id="yup.number.zadval034" />);

export const validateDatum = ({
    date,
    validFuture,
    validPast,
    createESD,
    updateESD,
    onlyValidate,
    notValidateBirth = false,
    minDate,
}: {
    date: string;
    validFuture?: boolean | undefined;
    validPast?: boolean | undefined;
    createESD?: boolean | undefined;
    updateESD?: boolean | undefined;
    onlyValidate?: boolean | undefined;
    notValidateBirth?: boolean | undefined;
    minDate?: Date;
}) => {
    const value = date?.split('.').reverse().join('-');
    const isValidDatum = validateDate(value);
    const isFutureDatum = isFuture(new Date(value));
    const oldest = format(startOfDay(subYears(new Date(), 110)), 'dd.MM.yyyy');
    const youngest = format(subYears(new Date(), 18), 'dd.MM.yyyy');
    const isMoreThen18yearsOld = isBefore(new Date(value), subYears(new Date(), 18));
    const maxThreeYears = isAfter(new Date(value), endOfDay(subYears(new Date(), -3)));
    const minFiveDaysAfterToday = isAfter(new Date(), endOfDay(subDays(new Date(value), -5)));
    const isPastDatum = isBefore(new Date(value), subDays(new Date(), 1));
    const isFirstDay = isFirstDayOfMonth(new Date(value));
    const isMoreThen110yearsOld = isBefore(new Date(value), startOfDay(subYears(new Date(), 110)));

    if (minDate && isBefore(new Date(value), minDate)) {
        return {message: <FormattedMessage id="yup.datum.minDate" values={{minDate: format(minDate, 'dd.MM.yyyy')}} />};
    }

    if (onlyValidate) {
        if (!(isValidDatum && isValid(new Date(value))) || value.length <= 7) {
            return {message: <FormattedMessage id="yup.datum.invalid" />};
        }
        return undefined;
    }
    if (!(isValidDatum && isValid(new Date(value))) || value.length <= 7) {
        return {message: <FormattedMessage id="yup.datum.invalid" />};
    }
    if (isFutureDatum && validFuture) {
        return {message: <FormattedMessage id="yup.datum.budoucnost" />};
    }
    if (((!isMoreThen18yearsOld && validFuture) || isMoreThen110yearsOld) && !notValidateBirth) {
        return {message: <FormattedMessage id="yup.datum.rozmezi" values={{oldest, youngest}} />};
    }

    if (isPastDatum && validPast) {
        return {message: <FormattedMessage id="yup.datum.minulost" />};
    }
    if (maxThreeYears && (createESD || updateESD)) {
        return {message: <FormattedMessage id="yup.datum.max.three.years" />};
    }
    if (minFiveDaysAfterToday && updateESD) {
        return {message: <FormattedMessage id="yup.datum.min.five.days" />};
    }
    if (!isFirstDay && createESD) {
        return {message: <FormattedMessage id="yup.first.date" />};
    }
    return undefined;
};

export const datumNotFuture = ({
    createESD,
    onlyValidate,
    notValidateBirth,
    minDate,
}: {
    createESD: boolean;
    onlyValidate?: boolean;
    notValidateBirth?: boolean;
    minDate?: Date;
}) =>
    string
        .test(
            '',
            ({value}) => validateDatum({date: value, validFuture: true, createESD, onlyValidate, notValidateBirth, minDate})?.message,
            (value) => {
                if (value) {
                    return !validateDatum({date: value, validFuture: true, createESD, onlyValidate, notValidateBirth, minDate});
                }
                return true;
            },
        )
        .nullable()
        .typeError('Pole je povinné');
export const datumFuture = ({
    createESD,
    updateESD,
    onlyValidate,
    minDate,
}: {
    createESD?: boolean;
    updateESD?: boolean;
    onlyValidate?: boolean;
    minDate?: Date;
}) =>
    string
        .test(
            '',
            ({value}) => validateDatum({date: value, validPast: true, createESD, onlyValidate, updateESD, minDate})?.message,
            (value) => {
                if (value) {
                    return !validateDatum({date: value, validPast: false, createESD, onlyValidate, updateESD, minDate});
                }
                return true;
            },
        )
        .typeError('Pole je povinné');
export const validOnlyDatum = ({
    onlyValidate,
    validateOposite,
    opositeName,
    validPast,
    validFuture,
    minDate,
}: {
    onlyValidate?: boolean;
    validateOposite?: boolean;
    opositeName?: string;
    validPast?: boolean;
    validFuture?: boolean;
    minDate?: Date;
}) =>
    string
        .test(
            '',
            ({value}) => validateDatum({date: value, onlyValidate, validPast, validFuture, minDate})?.message,
            (value) => {
                if (value) {
                    return !validateDatum({date: value, onlyValidate, validPast, validFuture, minDate});
                }
                return true;
            },
        )
        .test(
            '',
            () => <FormattedMessage id="yup.datumOd.datumDo" />,
            (value, ctx) => {
                if (validateOposite && value && opositeName && ctx.parent[opositeName]) {
                    const datumOd = new Date(value);
                    const datumDo = new Date(ctx.parent[opositeName]);
                    return !validateDatumOdIsLowerOrEqualThenDatumDo(datumOd, datumDo)?.error;
                }
                return true;
            },
        )
        .nullable()
        .typeError('Pole je povinné');

export const emailValidation = string
    .email()
    .transform((fn) => (fn?.length > 0 ? fn : undefined))
    .min(6)
    .max(254)
    .matches(
        /^[\w](?!.*([.])\1{1})(?:[a-zA-Z0-9]*[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]*[a-zA-Zá-žÁ-Ž0-9]*[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]){0,1}\@[a-zA-Z0-9]+(?:[-a-zA-Z0-9]+)*(\.[a-zA-Z0-9]+(?:[-a-zA-Z0-9]+)*){0,5}\.[a-zA-Z0-9]+(?:[-a-zA-Z0-9]+)*[a-zA-Z0-9]$/,
        () => <FormattedMessage id="yup.email" />,
    );

export const GENVAL051 = string
    .nullable()
    .max(10)
    .matches(/^[0-9a-zA-Z]*$/, () => <FormattedMessage id="yup.number" />);
export const onlyNumber = string.nullable().matches(/^[0-9]*$/, () => <FormattedMessage id="yup.number" />);
const obec = string.nullable().max(70);
const mesto = string.nullable().max(70);
const psc = string.nullable().max(10);
const ulice = string.nullable().max(60);
const castObce = string.nullable().max(40);
const cisloPopisne = GENVAL051;
const cisloOrientacni = string.nullable().max(10);
const cisloEvidencni = string.nullable().max(10);
export const adresaValidation = yup.object().nullable().shape({
    ulice: ulice.required(),
    psc: psc.required(),
    obec: obec.required(),
    castObce,
    cisloPopisne,
    cisloOrientacni,
    cisloEvidencni,
});
export const adresaKontaktyValidation = yup.object().nullable().shape({
    ulice: ulice.required(),
    psc: psc.required(),
    mesto: mesto.required(),
    mistniCast: castObce,
    cisloPopisne: cisloPopisne.required(),
    cisloOrientacni,
    cisloEvidencni,
});
export const adresaValidationNoRequired = yup.object().nullable().shape({
    ulice,
    psc,
    mesto,
    mistniCast: castObce,
    cisloPopisne,
    cisloOrientacni,
    cisloEvidencni,
});
const number = string.matches(/^\d+$/, () => <FormattedMessage id="yup.number" />);

export const telefonCZ = string.matches(/^(\+420|00420)??[6-7][0-9]{2}?[0-9]{3}?[0-9]{3}$/, () => <FormattedMessage id="yup.phone.cz" />);
export const telefonSK = string.matches(/^(\+421|00421)??[9][0-9]{2}?[0-9]{3}?[0-9]{3}$/, () => <FormattedMessage id="yup.phone.sk" />);
export const validateTelefon = ({telefon = '', prefix = true, required = true}: {telefon: string; prefix?: boolean; required?: boolean}) => {
    if (telefon?.length === 0 && required) {
        return string.required();
    }
    if (!required && (/^(00420|\+420)$/.test(telefon) || /^(00421|\+421)$/.test(telefon))) {
        return string.nullable();
    }

    if (/00420|\+420/.test(telefon)) {
        return telefonCZ;
    }
    if (/00421|\+421/.test(telefon)) {
        return telefonSK;
    }

    if (/^\d+$/.test(telefon) && prefix) {
        return number.test(
            '',
            () => 'Předvolba je povinná',
            () => {
                return false;
            },
        );
    }
    if (telefon?.length !== 9 && !prefix && telefon?.length) {
        return string.nullable().test(
            '',
            () => <FormattedMessage id="yup.phone.not.correct" />,
            () => {
                return false;
            },
        );
    }

    if (!/^\d+$/.test(telefon) && required) {
        return string.test(
            '',
            () => <FormattedMessage id="yup.phone.not.correct" />,
            () => {
                return false;
            },
        );
    }

    return required ? string.required() : string.nullable();
};
export const telefonValidation = yup.lazy((value: string) => validateTelefon({telefon: value}));
export const telefonValidationNoRequired = yup.lazy((value: string) => validateTelefon({telefon: value, required: false}));
export const telefonValidationWithoutPrefix = yup.lazy((value: string) => validateTelefon({telefon: value, prefix: false}));
export const telefonValidationWithoutPrefixNoRequired = yup.lazy((value: string) => validateTelefon({telefon: value, prefix: false, required: false}));

export const eanValidation = string
    .transform((fn) => (fn?.length > 0 ? fn : undefined))
    .matches(/^859182400(1|2)\d{8}$/, () => <FormattedMessage id="yup.ean" />);
export const eicValidation = string
    .transform((fn) => (fn?.length > 0 ? fn : undefined))
    .matches(/^27ZG900[a-zA-Z0-9]{9}$/, () => <FormattedMessage id="yup.eic" />);

export const jmenoValidation = string
    .required()
    .min(1)
    .max(70)
    .trim(() => <FormattedMessage id="yup.string.whitespace" values={{pole: 'Jméno'}} />)
    .matches(/^[a-zA-Zá-žÁ-Ž ]+$/, () => <FormattedMessage id="yup.string.only" values={{pole: 'Jméno'}} />);

export const prijmeniValidation = string
    .required()
    .max(70)
    .matches(/^(?!\d+$)(?!.*([ '-])\1{1})(?:[a-zA-Zá-žÁ-Ž]*[a-zA-Zá-žÁ-Ž '-]*[a-zA-Zá-žÁ-Ž])?$/, () => (
        <FormattedMessage id="yup.string.only" values={{pole: 'Příjmení'}} />
    ));
export const jmenoPrijmeniValidation = string
    .required()
    .max(100)
    .matches(/^(?!\d+$)(?!.*([ '-])\1{1})(?:[a-zA-Zá-žÁ-Ž]*[a-zA-Zá-žÁ-Ž '-]*[a-zA-Zá-žÁ-Ž])?$/, () => (
        <FormattedMessage id="yup.string.only" values={{pole: 'Jméno a příjmení osoby oprávněné jednat za vůdčí odběrné místo'}} />
    ));

const verifyICO = <T extends string | null | undefined>(ico: T) => {
    const icoArray = ico?.split('').map(Number);
    const icoArraySum = icoArray?.reduce((a, b, i) => (i < 7 ? a + b * (8 - i) : a), 0) ?? 0;
    const result = icoArraySum % 11;
    let cislo = 0;
    if (result === 0) {
        cislo = 1;
    } else if (result === 1) {
        cislo = 0;
    } else {
        cislo = 11 - result;
    }

    return cislo === icoArray?.[7];
};

const GENVAL015 = string
    .nullable()
    .transform((fn) => (fn?.length > 0 ? fn : undefined))
    .max(8)
    .matches(/^\d{8}$/, () => <FormattedMessage id="yup.number.ico" />);

export const icValidation = GENVAL015.test(
    '',
    () => <FormattedMessage id="yup.number.ico.no.valid" />,
    (value) => {
        if (isNotNullOrUndefinedOrEmpty(value)) {
            return verifyICO(value);
        }
        return true;
    },
);
export default yup;
